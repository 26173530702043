<template>
  <button
      class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
      type="button"
      @click="emit('close')">
    <span class="absolute -inset-2.5"></span>

    <icon name="material-symbols:close-rounded" size="24"/>
  </button>
</template>

<script setup>
const emit = defineEmits(['close'])
</script>
